import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  CardContent,
  StepConnector,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
  Rating,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { generateClient } from "aws-amplify/api";
import { useParams } from "react-router-dom";
import {
  ShoppingCart as OrderPlacedIcon,
  Build as ProcessingIcon,
  LocalShipping as ShippedIcon,
  DirectionsCar as OutForDeliveryIcon,
  Home as DeliveredIcon,
} from "@mui/icons-material";
import icon from "../assets/icon.png"; // Import the logo
import "./Style.css"; // Import the custom CSS
import LoadingScreen from "./LoadingScreen.tsx"; // Import the LoadingScreen component

const steps = [
  { label: "Order Placed", icon: <OrderPlacedIcon /> },
  { label: "Processing", icon: <ProcessingIcon /> },
  { label: "Shipped", icon: <ShippedIcon /> },
  { label: "Out for Delivery", icon: <OutForDeliveryIcon /> },
  { label: "Delivered", icon: <DeliveredIcon /> },
];

const statusMapping = {
  Order_Placed: 0,
  Pending: 1,
  Rescheduled: 1,
  Pickedup: 2,
  Delivery_Started: 3,
  Delivered: 4,
};

const CustomConnector = styled(StepConnector)(({ theme, activeStep }) => ({
  [`& .${StepConnector.line}`]: {
    borderColor: (props) =>
      props.activeStep >= props.index
        ? "#1876D2 !important"
        : theme.palette.grey[400],
    borderTopWidth: 3,
    borderRadius: 1,
    borderStyle: "solid !important",
  },
}));

const CustomStepConnector = ({ activeStep, index }) => {
  return <CustomConnector activeStep={activeStep} index={index} />;
};

const convertToLocalTime = (awsDateTime) => {
  if (!awsDateTime) return "N/A";
  const date = new Date(awsDateTime);
  return date.toLocaleString();
};

const getStatusMessage = (status) => {
  switch (status) {
    case "Order_Placed":
      return "Your order has been placed and is being processed.";
    case "Pending":
      return "Your order is currently being processed.";
    case "Pickedup":
      return "Your order has been picked up and is on its way.";
    case "Delivery_Started":
      return "Your order is out for delivery.";
    case "Delivered":
      return "Your order has been delivered.";
    default:
      return "Your order status is currently unknown.";
  }
};

const TrackOrder = () => {
  const API = generateClient();
  const { userId } = useParams();
  const [patientOrder, setPatientOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasRating, setHasRating] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [isDelivered, setIsDelivered] = useState(false);

  useEffect(() => {
    const fetchPatientOrder = async () => {
      try {
        const response = await axios.get(
          `https://9vzaj4sacj.execute-api.us-east-2.amazonaws.com/default/fetchPatientOrderForTracking-staging`,
          {
            headers: {
              "x-api-key": "U1jt48nKxK8067TaCgx666XQ3ad8jGO566zOf7bq",
            },
            params: {
              id: userId,
            },
          }
        );
        console.log("Patient Order:", response.data);
        setPatientOrder(response.data);
        const query = `query MyQuery {
          ratingsByDriverUserID(driverUserID: "${response.data.driveruserID}",filter: {ratingFrom: {eq: "Patient"}, deliveryID: {contains: "${userId}"}}) {
            items {
              id
            }
          }
        }

              `;
        // Call the createUserModel mutation with the input
        const checkRating = await API.graphql({
          query: query,
        });

        console.log(
          "Rating exist?",
          checkRating.data.ratingsByDriverUserID.items,
          checkRating.data.ratingsByDriverUserID.items.length > 0
        );

        const ratingExist =
          checkRating.data.ratingsByDriverUserID.items.length > 0
            ? true
            : false;
        setHasRating(ratingExist);
      } catch (error) {
        console.error("Error fetching patient order:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatientOrder();
  }, [userId]);
  useEffect(() => {
    if (patientOrder && patientOrder.deliveryStatus === "Delivered") {
      setIsDelivered(true);
    }
  }, [patientOrder]);
  async function ratingCheck() {
    const query = `query MyQuery {
  ratingsByDriverUserID(driverUserID: "${patientOrder.driveruserID}") {
    items {
      id
    }
  }
}

      `;
    // Call the createUserModel mutation with the input
    const checkRating = await API.graphql({
      query: query,
    });

    console.log(
      "Rating exist?",
      checkRating.data.ratingsByDriverUserID.items,
      checkRating.data.ratingsByDriverUserID.items > 0
    );

    const ratingExist =
      checkRating.data.ratingsByDriverUserID.items > 0 ? true : false;
    setHasRating(ratingExist);
  }

  if (loading) {
    return <LoadingScreen />;
  }
  if (!patientOrder) {
    return <Typography variant="h6">No order details available.</Typography>;
  }

  const currentStepIndex = statusMapping[patientOrder.deliveryStatus] || 0;
  const statusMessage = getStatusMessage(patientOrder.deliveryStatus);

  let dateLabel = "N/A";
  let dateLabelText = "N/A";

  switch (patientOrder.deliveryStatus) {
    case "Order_Placed":
    case "Pending":
      dateLabel = convertToLocalTime(patientOrder.deliveryDeadlineDT);
      dateLabelText = "Delivery Deadline";
      break;
    case "Pickedup":
      dateLabel = convertToLocalTime(patientOrder.pickupDT);
      dateLabelText = "Picked Up Date";
      break;
    case "Delivered":
      dateLabel = convertToLocalTime(patientOrder.deliveryCompletedDT);
      dateLabelText = "Delivered Date";

      break;
    default:
      dateLabel = "N/A";
      dateLabelText = "N/A";
  }

  async function createRatingFromPatient() {
    const url =
      "https://gq3hb37vs2u4crfdlzttgfjqv40eroqd.lambda-url.us-east-2.on.aws/";

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(
        "MY DATA",
        data.AWSDateTime,
        comment,

        userId,
        patientOrder.driveruserID,
        patientOrder.pharmacyID,
        rating
      );
      const query = `mutation MyMutation {
      createRating(input: {comment: "${comment}", completionDate: "${data.AWSDateTime}", deliveryID: "${userId}", driverUserID: "${patientOrder.driveruserID}", pharmacyID: "${patientOrder.pharmacyID}", ratingFrom: "Patient", ratingStar: ${rating}, status: "Completed"}) {
        id
      }
    }
    
      
      `;
      // Call the createUserModel mutation with the input
      const createUser = await API.graphql({
        query: query,
      });

      console.log("Res", createUser);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div style={{ backgroundColor: "#F1F5F9" }}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            flexDirection: "column",
            padding: { xs: 2, md: 4 },
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                padding: { xs: 2, md: 4 },
                marginTop: { xs: 5, md: 15 },
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                boxShadow:
                  "inset 0 2px 4px rgba(0, 0, 0, 0.1), inset 0 -2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction={{ xs: "column", md: "row" }}
                sx={{ mb: 4 }}
              >
                <Grid item>
                  <Box
                    component="img"
                    src={icon}
                    alt="RxRoute Logo"
                    sx={{
                      height: { xs: "50px", sm: "100px" },
                      width: "auto",
                    }}
                  />
                </Grid>
              </Grid>

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Tracking ID: {patientOrder.id}
              </Typography>

              <Paper
                sx={{
                  padding: { xs: 2, md: 3 },
                  marginTop: 4,
                  marginBottom: 4,
                }}
              >
                <Stepper
                  activeStep={currentStepIndex}
                  orientation={isMobile ? "vertical" : "horizontal"}
                  connector={
                    <CustomStepConnector activeStep={currentStepIndex} />
                  }
                  sx={{
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { xs: "flex-start", md: "center" },
                  }}
                >
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepLabel
                        StepIconComponent={() =>
                          React.cloneElement(step.icon, {
                            style: {
                              color:
                                index <= currentStepIndex
                                  ? "#1876D2"
                                  : "inherit",
                            },
                          })
                        }
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight:
                              index <= currentStepIndex ? "bold" : "normal",
                            color:
                              index <= currentStepIndex ? "#1876D2" : "inherit",
                          }}
                        >
                          {step.label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Paper>

              <Card
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: 3,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    Order Details
                  </Typography>
                  <Divider sx={{ marginBottom: 2, borderColor: "black" }} />
                  <Box sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Drug ID:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.drugID}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Delivery Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.deliveryAddress.address}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Emergency:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.isEmergency ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Special Note:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.note || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Schedule 1 Drugs:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.isSchOneDrugs ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Signed For:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: "black" }}>
                          {patientOrder.PatientUser.full_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>

              <Box sx={{ textAlign: "center", marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  {dateLabelText}: {dateLabel}
                </Typography>
                <Typography variant="body1">{statusMessage}</Typography>
                {isDelivered && (
                  <Typography variant="body1">
                    {hasRating
                      ? "Thank you for your rating!"
                      : `Please leave a rating for the driver`}
                  </Typography>
                )}
              </Box>
              {!hasRating && isDelivered && (
                <Box
                  sx={{
                    textAlign: "center",
                    marginBottom: 4,
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    onChange={(event, newValue) => {
                      setRating(newValue);
                      console.log("Rating is ", newValue);
                    }}
                    size="large"
                  />
                  <TextField
                    label="Comment"
                    placeholder="Optional"
                    focused
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setComment(event.target.value);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => {
                      console.log("Rating", rating);

                      if (rating !== 0) {
                        setHasRating(!hasRating);
                        createRatingFromPatient();
                        alert("Thank you for your rating!");
                      } else {
                        alert(
                          "Please add rating by filing in at least one star"
                        );
                      }
                    }}
                  >
                    Submit Rating
                  </Button>
                </Box>
              )}
              {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button variant="contained" color="primary">
              Track Another Order
            </Button>
            <Button variant="outlined" color="secondary">
              Contact Support
            </Button>
          </Box> */}
            </Box>

            {/* <SocialLinks>
          <FaFacebookF />
          <FaTwitter />
          <FaInstagram />
          <FaLinkedinIn />
        </SocialLinks> */}
          </Container>
          <Footer>
            <Typography variant="body2">RxRoute™</Typography>
          </Footer>
        </Box>
      )}
    </div>
  );
};

const SocialLinks = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
  marginTop: "16px",
});

const Footer = styled("footer")({
  marginTop: "1.5rem",
  height: "auto",
  padding: "1rem",
  backgroundColor: "#E3F2FD",
  width: "100%",
  textAlign: "center",
});

export default TrackOrder;
