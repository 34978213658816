import React from "react";
import "./App.css";
import TrackOrder from "./components/TrackOrder.tsx";
import Home from "./components/Home.tsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Order/:userId" element={<TrackOrder />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
