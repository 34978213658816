import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import icon from "../assets/icon.png";
import welcomeIMG_1 from "../assets/welcomeIMG_1.png";
import welcomeIMG_2 from "../assets/welcomeIMG_2.png";
import welcomeIMG_3 from "../assets/welcomeIMG_3.png";
import newMapImage from "../assets/maproute2.png"; // Make sure to update the path according to your directory structure

const Home: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 3,
          padding: 4,
          marginBottom: 4,
          textAlign: "center",
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: 2 }}>
              <img src={icon} alt="RxRoute Logo" style={{ height: 80 }} />
            </Box>

            <Typography
              variant="h4"
              sx={{ fontWeight: 700, color: "#333", marginBottom: 2 }}
            >
              Your trusted partner in medicine delivery.
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ color: "#666", marginBottom: 4 }}
            >
              Get your medications delivered right to your doorstep with ease
              and reliability.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <img
              src={newMapImage}
              alt="Map"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, textAlign: "center" }}>
            <CardMedia
              component="img"
              height="200"
              image={welcomeIMG_1}
              alt="Convenient Deliveries"
            />
            <CardContent>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginBottom: 1, color: "#4face7" }}
              >
                Convenient Deliveries
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Get your medications delivered right to your doorstep with ease
                and reliability.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, textAlign: "center" }}>
            <CardMedia
              component="img"
              height="200"
              image={welcomeIMG_2}
              alt="Timely Service"
            />
            <CardContent>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginBottom: 1, color: "#4face7" }}
              >
                Timely Service
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We ensure your prescriptions are delivered on time, every time.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, textAlign: "center" }}>
            <CardMedia
              component="img"
              height="200"
              image={welcomeIMG_3}
              alt="Reliable Support"
            />
            <CardContent>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginBottom: 1, color: "#4face7" }}
              >
                Reliable Support
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our customer support team is here to help with all your delivery
                needs.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
