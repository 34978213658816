/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "a70d8a10d785451f89b35eb6712d2d72",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a70d8a10d785451f89b35eb6712d2d72",
            "region": "us-east-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "a70d8a10d785451f89b35eb6712d2d72",
                "region": "us-east-2"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://3dsd77krqzejrh54v4goaf7mcq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rzgb4jmnvrc3vgshyvvo67z42u",
    "aws_cognito_identity_pool_id": "us-east-2:6f983b0a-8a2a-4ca1-b725-cce2e6fa38e8",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_0S4C0Jfiv",
    "aws_user_pools_web_client_id": "5incinkqp9cjf5cs1bk44m5uio",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "rxroutebucketdf226-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
